import React from 'react';
// import { HeaderLabel } from '@backstage/core-components';
import { getTimes } from './world-clock.utils';
import { TimeObj, ClockConfig } from './world-clock.types';
import Box from '@mui/material/Box';
import { useWorldClockStyles } from './world-clock.styles';

/** @public */
export type { ClockConfig };

const WorldClock = (props: {
  clockConfigs: ClockConfig[];
  customTimeFormat?: Intl.DateTimeFormatOptions;
}) => {
  const classes = useWorldClockStyles();
  const { clockConfigs, customTimeFormat } = props;

  const defaultTimes: TimeObj[] = [];
  const [clocks, setTimes] = React.useState(defaultTimes);

  React.useEffect(() => {
    setTimes(getTimes(clockConfigs, customTimeFormat));

    const intervalId = setInterval(() => {
      setTimes(getTimes(clockConfigs, customTimeFormat));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [clockConfigs, customTimeFormat]);

  if (clocks.length !== 0) {
    return (
      <div className={classes.root}>
        {clocks.map(clock => (
          <Box className={classes.container}>
            <Box className={classes.label}>{clock.label}</Box>
            <Box className={classes.time}>
              <time dateTime={clock.dateTime}>{clock.value}</time>
            </Box>
          </Box>
        ))}
      </div>
    );
  }
  return null;
};

export default WorldClock;
