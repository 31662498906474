import { makeStyles } from '@mui/styles';

// Uncomment theme if you need to access the theme's values
export const useWorldClockStyles = makeStyles(
  (/* theme */) => ({
    root: {
      display: 'flex',
    },
    container: {
      minWidth: '3.75rem',
      marginRight: '2rem',
      '@media(max-width: 1250px)': {
        marginRight: '1rem',
      },
    },
    label: {
      fontWeight: 'bold',
      marginBottom: '.25rem',
      color: '#fff',
    },
    time: {
      fontWeight: 'bold',
      color: '#fff',
      paddingLeft: '.6rem',
    },
  }),
  { name: 'HeaderWorldClock' },
);
